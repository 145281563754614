<template>

  <div class="banner">
  </div>

  <div class="breif">
    <div class="area-title">
      <p class="title">企业简介</p>
    </div>
    <div class="breif-content">
      <div class="breif-content--item">
        成都至灿集团有限公司（简称：至灿集团）成立于2019年，位于成都IFS国际金融中心2号楼38楼。
      </div>
      <div class="breif-content--item">
        至灿集团是一家集产品采购、研发、生产、销售、运营、售后、仓储管理、配送为一体的餐饮供应链服务平台，致力于为各类餐饮客户提供综合供应链管理与服务。
      </div>
      <div class="breif-content--item">
        目前已在北京、上海、广州、深圳、成都、 杭州等50余座城市建设分仓，基本实现了物流网络全国覆盖。
      </div>
    </div>
  </div>
  <div class="zone">
    <div class="area-title">
      <p class="title">企业文化</p>
    </div>
    <div>
      <div class="zone-child">
        <div class="cost">
          <div
            class="cost-item"
            v-for="(item, index) in cultures"
            :key="index"
          >
            <div
              class="cost-item--default"
              :style="{'background-image': 'url('+item.bg+')'}"
            >
              <img
                class="cost-item--default_icon"
                :src="item.icon"
              >
              <p
                class="cost-item--default_title"
                v-for="(t, idx) in item.title"
                :key="idx"
              >{{t}}</p>
            </div>
            <div class="cost-item--hover">
              <img
                class="cost-item--hover_icon"
                :src="item.hover.icon"
              >
              <p
                class="cost-item--hover_title"
                v-for="(t, idx) in item.hover.title"
                :key="idx"
              >{{t}}</p>
              <div class="cost-item--hover_desc">
                <p
                  class="cost-item--hover_di"
                  v-for="(d, idx) in item.hover.desc"
                  :key="idx"
                >{{d}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "about",
  components: {

  },
  data() {
    return {
      cultures: [
        {
          bg: require('@/assets/images/strength-about/v1/bg1.png'),
          icon: require('@/assets/images/strength-about/v1/icon1.png'),
          title: ['诚信于行动', '创造价值'],
          hover: {
            icon: require('@/assets/images/strength-about/v1/icon11.png'),
            title: ['诚信于行动', '创造价值'],
            desc: ['品质第一是船', '服务第一是帆', '行业第一是指南']
          }
        },
        {
          bg: require('@/assets/images/strength-about/v1/bg2.png'),
          icon: require('@/assets/images/strength-about/v1/icon2.png'),
          title: ['服务于客户', '完成使命'],
          hover: {
            icon: require('@/assets/images/strength-about/v1/icon22.png'),
            title: ['服务于客户', '完成使命'],
            desc: ['做好供应', '把好品质', '利好客户', '创造价值']
          }
        },
        {
          bg: require('@/assets/images/strength-about/v1/bg3.png'),
          icon: require('@/assets/images/strength-about/v1/icon3.png'),
          title: ['奉献于行业', '追求愿景'],
          hover: {
            icon: require('@/assets/images/strength-about/v1/icon33.png'),
            title: ['奉献于行业', '追求愿景'],
            desc: ['开创餐饮新蓝海', '惠利遍及行业间']
          }
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.banner {
  height: 36.5vw;
  background-image: url('../../../assets/images/strength-about/v1/banner.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.zone {
  padding: 4.9rem 0 0;
}
.breif {
  height: 41.6vw;
  padding: 9.5vw 8.5vw;
  background-image: url('../../../assets/images/strength-about/v1/breif.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  .area-title {
    display: inline-block;
    text-align: left;
    margin-bottom: 4vw;
    .title {
      color: #ffffff;
    }
  }
  .breif-content {
    color: #ffffff;
    .breif-content--item {
      width: 30.4vw;
      font-size: 0.83vw;
      line-height: 1.56vw;
      margin-bottom: 1.56vw;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
}
.zone-child {
  padding-top: 12rem;
  .child-title {
    color: #ffffff;
    background-color: #e61d0e;
    border-radius: 1rem;
    display: inline-block;
    height: 2.8rem;
    line-height: 2.8rem;
    padding: 0.6rem 2rem;
    font-size: 3rem;
    font-weight: bold;
    margin-left: 50%;
    margin-bottom: 3.7rem;
    transform: translateX(-50%);
    box-shadow: 0 0 1rem rgba(128, 128, 128, 0.315);
  }
  &:last-child .child-title {
    color: #333333;
    background-color: #ffffff;
    border-radius: 0;
  }
  .cost {
    width: 100%;
    display: flex;
    .cost-item {
      position: relative;
      flex: 0 1 auto;
      width: 33.33vw;
      height: 33.33vw;
      text-align: center;
      box-sizing: border-box;
      &:hover {
        .cost-item--hover {
          opacity: 1;
          transform: scale(1);
        }
      }
      &--default {
        width: 100%;
        height: 100%;
        padding-top: 9vw;
        box-sizing: border-box;
        background-position: -3vw -3vw;
        background-size: 120% 120%;
        background-repeat: no-repeat;
        &_icon {
          width: 6vw;
          height: auto;
          margin-bottom: 3vw;
        }
        &_title {
          font-size: 1.35vw;
          color: #ffffff;
          font-weight: bold;
          margin-bottom: 1vw;
        }
      }
      &--hover {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        padding-top: 2.6vw;
        background-color: #e61d0e;
        box-sizing: border-box;
        opacity: 0;
        transform: scale(0.1);
        transition: all 0.5s ease-in-out;
        &_icon {
          width: 6vw;
          height: auto;
          margin-bottom: 2.9vw;
        }
        &_title {
          font-size: 1.35vw;
          color: #ffffff;
          font-weight: bold;
          margin-bottom: 1vw;
        }
        &_desc {
          margin-top: 3vw;
          margin-bottom: 2vw;
          font-size: 1vw;
          font-weight: 500;
        }
        &_di {
          color: #ffffff;
          margin-bottom: 1.4vw;
        }
      }
    }
  }
}
.area-title {
  text-align: center;
  .title {
    position: relative;
    font-size: 3rem;
    font-weight: bold;
    color: #000000;
    &::before {
      position: absolute;
      top: 5.4rem;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      width: 10rem;
      height: 0.4rem;
      background-color: #e61d0e;
      border-radius: 0.2rem;
    }
  }
}
</style>
